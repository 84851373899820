import URI from 'urijs';

// Groups specified facets into a collapsible "advanced filter" menu
// Filters to collapse are specified by "facetsToCollapse" in the vertical config
export async function collapseFacets(filterSelectors = []) {
  if (!filterSelectors.length) return;

  const advanceFilterDiv = document.querySelector('.js-advanced-filters');
  const advanceFilterToggle = document.querySelector('.js-advance-filters-toggle');
  if (advanceFilterDiv){
    advanceFilterDiv.innerHTML = '';
    for (let filterSelector of filterSelectors) {
      const docEl = document.querySelector(`[data-label="${filterSelector}"]`);
      if (docEl) {
        advanceFilterDiv.appendChild(docEl.parentNode);
      }
    }
  }

  // Hide filter if no options present
  if (advanceFilterDiv && advanceFilterDiv.childElementCount == 0 && advanceFilterToggle){
    advanceFilterToggle.classList.add('is-hidden');
  }

  // This function is called "onMount" in facets-override.hbs
  // "onMount" executes twice for every load
  // We set a 'listener' attribute to make sure we only add the click listener once
  if (advanceFilterToggle && advanceFilterToggle.getAttribute('listener') !== 'true') {
    advanceFilterToggle.classList.remove('is-hidden');
    advanceFilterDiv.classList.add('is-minimized');
    advanceFilterToggle.setAttribute('listener', 'true');

    advanceFilterToggle.addEventListener('click', () => {
      advanceFilterDiv.classList.toggle('is-minimized');
      advanceFilterToggle.classList.toggle('is-active');
    });
  }
}

export function initResetFacets() {
  let reset = document.querySelector('.js-yxt-FilterBox-reset');
  if (reset) {
    reset.addEventListener('click', () => {
      CollapsibleFilters.Helpers.resetAllFilters();
      CollapsibleFilters.Helpers.verticalSearch({ useFacets: true });
    });
  }
}

export function initFacetTransfer() {
  const urlParams = new URLSearchParams(window.location.search);
  const facetsOnAllVerticals = ["c_servicesCustom", "c_priorities", "c_taxonomyTopics", "c_authors"];

  // Do nothing if no facetFilters are applied
  if (!urlParams.get('facetFilters')) return;

  const facetFilters = JSON.parse(urlParams.get('facetFilters'));
  for (const key in facetFilters) {
    if (facetFilters.hasOwnProperty(key)) {
      if (!facetsOnAllVerticals.includes(key)) {
        delete facetFilters[key];
      }
    }
  }
  const verticalLinks = Array.from(document.querySelectorAll('.js-yxt-navItem')).filter((link) => !link.href.includes('/index.html?'));

  if (verticalLinks) {
    verticalLinks.forEach((link) => {
      const linkUrl = new URI(link.href);
      linkUrl.setQuery('facetFilters', JSON.stringify(facetFilters));
      link.href = linkUrl.href();
    })
  }
}